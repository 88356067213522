<template>
	<div>
		<b-sidebar id="sidebar-task-handler" sidebar-class="sidebar-lg" :visible="isSiteUserHandlerSidebarActive" bg-variant="white" shadow backdrop no-header right @change="(val) => { $emit('update:is-site-user-handler-sidebar-active', val); }" @hidden="clearEditForm">
			<template #default="{ hide }">
				<!-- Header -->
				<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
					<h5 class="mb-0">
						Site Permissions
					</h5>
					<div>
						<feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="21" @click="hide" />
					</div>
				</div>

				<!-- Form -->
				<b-form class="p-2">

					<b-form-group v-if="!get_site_users.length && siteUser != null" v-for="(siteUser, index) in get_site_users" :key="siteUser.id">
						<h5 class="text-break">{{ siteUser.email }}</h5>
						<div class="demo-inline-spacing">
							<b-form-radio v-model="roleSelectedRadio[siteUser.id]" :name="siteUser.id" value="1" class="custom-control-danger mt-0" @change="changeUserRole($event, siteUser)"> Owner </b-form-radio>
							<b-form-radio v-model="roleSelectedRadio[siteUser.id]" :name="siteUser.id" value="2" class="custom-control-warning mt-0" @change="changeUserRole($event, siteUser)"> Admin </b-form-radio>
							<b-form-radio v-model="roleSelectedRadio[siteUser.id]" :name="siteUser.id" value="3" class="custom-control-secondary mt-0" @change="changeUserRole($event, siteUser)"> None </b-form-radio>
							<!-- Need to add new way for remove user -->
							<feather-icon v-if="authUserRole == 1 && siteUser.site_role != 1" size="21" class="cursor-pointer custom-control-danger mt-0 pull-right text-danger" icon="TrashIcon" @click="removeSiteUser(siteUser.id);" />
							<!-- Need to add new way for remove user -->
						</div>
						<hr>
					</b-form-group>

				</b-form>
			</template>
		</b-sidebar>
	</div>
</template>

<script>
	import {
		BSidebar, BForm, BFormGroup, BFormInput, BAvatar, BButton, BFormInvalidFeedback, BButtonGroup, BRow, BCol, BFormRadio, BFormRadioGroup
	} from 'bootstrap-vue'
	import Ripple from 'vue-ripple-directive'
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	export default {
		components: {
			// BSV
			BButton,
			BSidebar,
			BForm,
			BFormGroup,
			BFormInput,
			BAvatar,
			BFormInvalidFeedback,
			BButtonGroup,
			BRow,
			BCol,
			BFormRadio,
			BFormRadioGroup,
		},
		directives: {
			Ripple,
		},
		model: {
			prop: 'isSiteUserHandlerSidebarActive',
			event: 'update:is-site-user-handler-sidebar-active',
		},
		props: {
			isSiteUserHandlerSidebarActive: {
				type: Boolean,
				required: true,
			},
			editSiteObject_prop: null,
		},
		watch: {
			editSiteObject_prop(newVal, OldVal) {
				this.siteOwnerstring = newVal.site.role_ids
				return newVal;
			}
		},
		computed: {
			get_site_users() {
				let users = this.editSiteObject_prop.users ?? {};
				if (!_.isEmpty(users)) {

					/* FOR RADIO BUTTON CHECKED : START */
					for (const [user_id, user] of Object.entries(users)) {
						if (!_.isEmpty(user)) {
							this.roleSelectedRadio[user_id] = user.site_role; /* SET VALUE FOR CHECKED="CHECKED" */
						}
					}
					/* FOR RADIO BUTTON CHECKED : END */
					return users;
				}
				return null;
			}
		},
		data() {
			return {
				roleSelectedRadio: {},
				siteOwnerstring: "",
				authUserRole: this.$root.authUserObject.role
			}
		},
		methods: {
			clearEditForm() {
			},
			changeUserRole(role, siteUser) {
				const siteOwnersArray = this.siteOwnerstring.split(","); 
				const countOwners = siteOwnersArray.filter(item => item === '1').length;
				if(siteUser.site_role == 1 && role != 1 && countOwners <= 1 ){
					this.isSiteUserHandlerSidebarActive = false;
					this.$emit('refreshSiteList', true);
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Notification',
							icon: 'BellIcon',
							variant: 'danger',
							text: 'This role cannot be changed, at least one owner required.',
						},
					});
				}else{
					let site = this.editSiteObject_prop.site;
					this.$globalAPI.post('site-user/change-site-user-permission', {
						'auth_id': this.$root.authUserObject.id ?? '',
						'site_user_id': siteUser.id,
						'site_id': site.siteId,
						'role_id': role,
					}).then((response) => {
						response = response.data;
						if (response.status) {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: 'Notification',
									icon: 'BellIcon',
									variant: 'success',
									text: response.message,
								},
							});
							this.$emit('update:is-site-user-handler-sidebar-active', false); /* CLOSE SIDEBAR */
							this.$emit('refreshSiteList', true); /* REFRESH MAIN SITE LIST */
						} else {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: 'Notification',
									icon: 'BellIcon',
									variant: 'danger',
									text: response.message,
								},
							});
						}
					});
				}
			},

			removeSiteUser(id){
				let site = this.editSiteObject_prop.site;
				let siteUsers =  site.user_ids.split(",");

				if(siteUsers.length > 1){
					this.$swal({
						title: 'Are you sure to delete?',
						icon: 'warning',
						showCancelButton: true,
						confirmButtonText: 'Yes, delete it!',
						customClass: {
							confirmButton: 'btn btn-primary',
							cancelButton: 'btn btn-outline-danger ml-1',
						},
						buttonsStyling: false,
					}).then((result) => {
						if (result.value) {
							this.$globalAPI.post('site-user/remove-site-user', {
								'site_user_id': id,
								'site_id': site.siteId,
							}).then((response) => {
								response = response.data;
								if (response.status) {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: 'Notification',
											icon: 'BellIcon',
											variant: 'success',
											text: response.message,
										},
									});
									this.$emit('refreshSiteList', true);
									this.isSiteUserHandlerSidebarActive = false
								} else {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: 'Notification',
											icon: 'BellIcon',
											variant: 'danger',
											text: response.message,
										},
									});
								}
							});
						} else {
							return false;
						}
					})
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Notification',
							icon: 'BellIcon',
							variant: 'danger',
							text: "At least one user is require",
						},
					});
					this.isSiteUserHandlerSidebarActive = false
				}
			}
		},
	}
</script>

<style lang="scss" scoped>
	@import '~@core/scss/base/bootstrap-extended/include';
</style>