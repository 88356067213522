<template>
	<b-modal ref="modal-add-site-user" id="modal-add-site-user" title="Invite someone to your team" cancel-variant="outline-secondary" ok-title="Save" cancel-title="Close" @ok="handleOk" @hidden="hideBlankData">

		<validation-observer ref="siteGeneralForm">
			<b-form @submit.prevent="siteValidationForm">
				<b-form-group label="Enter Email" label-for="email">
					<validation-provider #default="{ errors }" name="email" rules="required|email" :custom-messages="{ required: 'Please enter email', email: 'Please enter valid email' }">
						<b-form-input id="email" type="email" v-model="siteObject.email" name="email" placeholder="Email Address" />
						<small class="text-danger">{{ errors[0] }}</small>
					</validation-provider>
				</b-form-group>

				<b-form-group label="Select Site" label-for="siteDropdownForAddUser">
					<validation-provider #default="{ errors }" name="site" rules="required" :custom-messages="{ required: 'Please select site' }">
						<Select2 placeholder="Select Site" id="siteDropdownForAddUser" name="site" v-model="siteObject.site" :options="siteDropDownOptions" @change="selectSiteChangeEvent($event)" :settings="{'dropdownParent': '#modal-add-site-user .modal-content' }" />
						<small class="text-danger">{{ errors[0] }}</small>
					</validation-provider>
				</b-form-group> 
			</b-form>

		</validation-observer> 
	</b-modal>
</template>

<script>
	import {
		BFormInput, BInputGroup, BModal, VBModal, BButton, BForm, BFormGroup
	} from 'bootstrap-vue'
	import Ripple from 'vue-ripple-directive'
	import { ValidationProvider, ValidationObserver } from 'vee-validate'
	import { required } from '@validations'
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	export default {
		components: {
			BFormInput, BInputGroup, BModal, VBModal, BButton, BForm, BFormGroup, ValidationProvider, ValidationObserver,
		},
		directives: {
			Ripple,
		},
		model: {},
		props: {},
		watch: {},
		computed: {},
		created: function () {
			this.$store.commit('app/TOGGLE_OVERLAY', true); /* FOR BLUR BACKEND */
			this.fetchSitesAndUsers();
		},
		data() {
			return {
				filterSite: '',
				siteDropDownOptions: [],
				siteObject: {
					email: null,
					site: null
				},
				required,
			}
		},
		methods: {
			hideModal() {
				this.$refs['modal-add-site-user'].hide()
			},
			selectSiteChangeEvent(val) {
				this.siteObject.site = val;
			},
			fetchSitesAndUsers() {
				this.$globalAPI.get('sites-users').then((response) => {
					response = response.data;
					if (response.status) {
						for (const site of response.sites) {
							this.siteDropDownOptions.push({
								'id': site.siteId,
								'text': site.siteName
							});
						}
					}
					this.$store.commit('app/TOGGLE_OVERLAY', false); /* FOR BLUR BACKEND */
				});
			},
			siteValidationForm() {
				this.$refs.siteGeneralForm.validate().then(success => {
					if (success) {
						this.$globalAPI.post('site-user/invite-new-user', {
							'user_id': this.$root.authUserObject.id ?? '',
							'site_id': this.siteObject.site,
							'email': this.siteObject.email,
						}).then((response) => {
							response = response.data;
							if (response.status) {
								this.$refs['modal-add-site-user'].hide();
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'success',
										text: response.message,
									},
								});
								this.$emit('refreshSiteList', true);
							} else {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'danger',
										text: response.message,
									},
								});
							}
						});
					}
				})
			},

			handleOk(bvModalEvent) {
            	// Prevent modal from closing
				bvModalEvent.preventDefault()
				// Trigger submit handler
				this.siteValidationForm()
			},
			hideBlankData() {
				this.siteObject = {};
			},
		},
	}
</script>

<style lang="scss" scoped>
</style>