<template>
	<!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
	<div style="height: inherit">
		<div class="body-content-overlay" :class="{'show': mqShallShowLeftSidebar}" @click="mqShallShowLeftSidebar = false" />
		<div class="todo-app-list">

			<!-- App Searchbar Header -->
			<div class="app-fixed-search d-flex align-items-center">

				<!-- Toggler for mobile -->
				<div class="sidebar-toggle d-block d-lg-none ml-1">
					<feather-icon icon="MenuIcon" size="21" class="cursor-pointer" @click="mqShallShowLeftSidebar = true" />
				</div>

				<!-- Searchbar -->
				<div class="d-flex align-content-center justify-content-between w-100">
					<b-input-group class="input-group-merge">
						<b-input-group-prepend is-text>
							<feather-icon icon="UserCheckIcon" size='21' />
							<h5 class="font-weight-bolder ml-50 mt-50"> Sites And Users </h5>
						</b-input-group-prepend>
					</b-input-group>
				</div>
			</div>

			<!-- Todo List -->
			<vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150 }" class="todo-task-list-wrapper list-group scroll-area">
				<ul class="todo-task-list media-list">
					<li v-for="site in get_app_sites" :key="site.id" class="todo-item" @click="editSiteUsersClick(site)">
						<div class="todo-title-wrapper">
							<div class="todo-title-area">
								<div class="title-wrapper">
									<feather-icon icon="StarIcon" size='21' />
									<span class="todo-title">{{ site.siteName }}</span>
								</div>
							</div>
							<div class="todo-item-action" :style="{'word-wrap':'break-word'}">
								<div class="badge-wrapper mr-1 d-flex justify-content-end flex-wrap">
									<b-badge v-if="site.user_ids" v-for="(val, index) in getUserIdArray(site.user_ids)" :key="index" pill :variant="`light-primary`" class="text-capitalize m-25">
										{{ app_users[val].email }}
									</b-badge>
								</div>
								<b-avatar v-if="site.siteImage" size="32" :src="$root.getUploadFilePath(`website/thumbs/${site.siteImage}`)" :variant="`light-primary`" />
								<b-avatar v-else size="32" variant="light-secondary">
									<feather-icon icon="UserIcon" size="16" />
								</b-avatar>
							</div>
						</div>
					</li>
				</ul>
				<div class="no-results" :class="{'show': !get_app_sites.length}">
					<h5>No Items Found</h5>
				</div>
			</vue-perfect-scrollbar>
		</div>

		<!-- Task Handler -->
		<site-user-update-sidebar v-model="isSiteUserHandlerSidebarActive" :editSiteObject_prop="edit_site_object" @refreshSiteList="getSitesAndUsers()" />

		<!-- Sidebar -->
		<portal to="content-renderer-sidebar-left">
			<filters-left-sidebar :is-site-user-handler-sidebar-active.sync="isSiteUserHandlerSidebarActive" :class="{'show': mqShallShowLeftSidebar}" @selectedFilters="selectedFilterEvent($event)" @close-left-sidebar="mqShallShowLeftSidebar = false" />
		</portal>

		<add-site-user :siteList_prop="app_sites" @refreshSiteList="getSitesAndUsers()" /> <!-- FOR ADD NEW USER TO SITE -->

	</div>
</template>

<script>
	import store from '@/store'
	import {
		ref, watch, computed, onUnmounted,
	} from '@vue/composition-api'
	import {
		BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem, BFormCheckbox, BBadge, BAvatar
	} from 'bootstrap-vue'
	import VuePerfectScrollbar from 'vue-perfect-scrollbar'
	import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
	import FiltersLeftSidebar from './FiltersLeftSidebar.vue'
	import todoStoreModule from './todoStoreModule'
	import SiteUserUpdateSidebar from './SiteUserUpdateSidebar.vue'
	import AddSiteUser from './AddSiteUser.vue'

	export default {
		components: {
			BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem, BFormCheckbox, BBadge, BAvatar,
			// App SFC
			VuePerfectScrollbar,
			FiltersLeftSidebar,
			SiteUserUpdateSidebar,
			AddSiteUser,
		},
		data: function () {
			return {
				searchQuery: '',
				app_sites: {},
				app_users: {},
				filtersObject: {},
				isSiteUserHandlerSidebarActive: false,
				siteObject: {},
			};
		},
		created: function () {
			this.getSitesAndUsers();
		},
		computed: {
			get_app_sites() {
				let sortedSiteArray = _.sortBy(this.app_sites, "siteName"); /* FOR SORT ARRAY TO A-Z */
				this.$store.commit('app/TOGGLE_OVERLAY', Boolean(this.searchQuery)); /* FOR BLUR BACKEND */

				/* APPLY FILTERS : START */
				if (this.filtersObject.site_id && this.filtersObject.user_id) {
					return _.filter(sortedSiteArray, (item) => { /* FOR FILTER SITES WITH SEARCH */
						let site_users = _.split(item.user_ids, ',');
						if (item.siteId == this.filtersObject.site_id && site_users.includes(this.filtersObject.user_id)) {
							return true;
						}
						return false;
					});
				} else if (!this.filtersObject.site_id && this.filtersObject.user_id) {
                    return _.filter(sortedSiteArray, (item) => { /* FOR FILTER SITES WITH SEARCH */
						let site_users = _.split(item.user_ids, ',');
						if (item.siteId != this.filtersObject.site_id && site_users.includes(this.filtersObject.user_id)) {
							return true;
						}
						return false;
					});
                } else if (this.filtersObject.site_id && !this.filtersObject.user_id) {
                    return _.filter(sortedSiteArray, (item) => { /* FOR FILTER SITES WITH SEARCH */
						let site_users = _.split(item.user_ids, ',');
						if (item.siteId == this.filtersObject.site_id && !site_users.includes(this.filtersObject.user_id)) {
							return true;
						}
						return false;
					});
                }
				/* APPLY FILTERS : END */

				return sortedSiteArray;
			},
			edit_site_object() {

				let site_users = _.split(this.siteObject.user_ids, ',');
				let site_role_ids = _.split(this.siteObject.role_ids, ',');

				/* MAKE USER OBJECT : START */
				let usersObject = {};
				for (const index in site_users) {
					let user_id = site_users[index];
					usersObject[user_id] = _.merge(this.app_users[user_id], { 'site_role': site_role_ids[index]});
				}
				/* MAKE USER OBJECT : END */

				return {
					site: this.siteObject,
					users: usersObject
				};
			}
		},
		methods: {
			getSitesAndUsers() {
				this.$siteAPI.get('permission/list', {
					'user_id': this.$root.authUserObject.id,
				}).then((response) => {
					this.app_sites = response.data.sites;
					this.app_users = _.keyBy(response.data.users, 'id')
				});
			},
			getUserIdArray(ids) {
				return _.split(ids, ',');
			},
			selectedFilterEvent(dataObject) { /* CALLING FROM LEFT SIDE FILTER */
				this.mqShallShowLeftSidebar = false; /* CLOSE LEFT SITE ON MOBILE VIEW */
				this.filtersObject = dataObject;
			},
			editSiteUsersClick(site) {
				this.siteObject = site;
				this.isSiteUserHandlerSidebarActive = true;
			}
		},
		setup() {

			const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

			return {
				mqShallShowLeftSidebar, /* LEFT SIDEBAR RESPONSIVE */
			}
		},
	}
</script>

<style lang="scss">
	@import "~@core/scss/base/pages/app-todo.scss";
</style>